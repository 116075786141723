.form-group .file-list {

  /* file upload button */
  li.file-list--upload {
    width: 140px;
    margin-bottom: 2em;
    float: left;
    margin-right: 1em;
    color: #999;

    input {
      text-indent: -999em;
      width: 140px;
      height: 90px;
      background: url("/static/images/icon-cloud.png") transparent no-repeat center 15px;
      border-radius: 3px;
      border: 1px dashed $border;
      color: #fff;
      font-size: 1px;
      position: relative;
      z-index: 2;
      &:hover {
        cursor: pointer;
        border-color: $blue;
      }
      &:hover + span,
      + span:hover {
        color: $blue;
      }
      + span {
        margin-top: -27px;
        display: block;
        text-align: center;
        margin-bottom: 15px;
        position: relative;
        z-index: 1;
      }
    }
    span {
      display: block;
      text-align: center;
    }
    span + span {
      font-size: 0.8em;
    }
  }

  li.file-list--item {
    float: left;
    margin-right: 1em;
    margin-bottom: 1em;
    min-height: 175px;
    //border-bottom: 1px solid $border;

    .attachment-image {
      overflow: hidden;
      height: 90px;
      text-align: center;
      border: 1px solid #707070;
      display: table-cell;
      width: 140px;
      vertical-align: middle;
      img {
        width: auto;
        max-height: 90px;
        display: inline-block;
      }
    }

    &.uploading {display: none;}

  }

}

.file-item-uploading {
  @extend .attachment--file;

  .uploading-text {
    color: #999;
    margin: 7px 0;
    font-size: 12px;
  }
}

.file-item-upload-error {
  @extend .attachment--file;

  color: red;
  font-size: 14px;
  .error-text {margin: 7px 0;}
}

.attachment--file {
  width: 220px;
  padding: 7px 10px;
  border: 1px solid #707070;
  height: 90px;
  line-height: 18px;
  .filename {
    width: 200px;
    height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .uploaded {
    font-size: 0.9em;
    color: #707070;
    margin-bottom: 0;
    font-weight: 300;
  }
  + .select2-container--default .select2-selection--multiple .select2-selection__rendered {
    width: 220px;
  }

}



/*
** Overwrite Select2 libs.
*/
.file-list {
  .select2-container--default {
    width: 100% !important;
    max-width: 250px;
  }
  .select2-container--default .select2-selection--multiple,
  .select2-container--default.select2-container--focus .select2-selection--multiple {
    border-width: 0 0 1px 0;
    border-bottom-color: #707070;
    border-radius: 0;
  }
  .select2-container--default .select2-selection--multiple {
    background-color: transparent;
  }
  .select2-container--default .select2-selection--multiple .select2-selection__rendered {
    padding: 0;
  }

}

.file-list--item { position: relative;}
.youtube-list--item {
  width: 140px;
  position: relative;
  margin: 0 1em 1em 0;
  float: left;
}

.file-list { overflow: hidden; padding-top: 1em;}

.file-list--item, .youtube-list--item {
    .btn-close {
        position: absolute;
        top: -10px;
        right: -10px;
    }
}

/* upload field attachments */
.files-widget-deleted {
  display: none !important; // hide block undofile
}
