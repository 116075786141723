.field-label { font-size: 14px;}
.field-content { font-size: 14px;}
.project-phase {
    .list-group-item {
        .badge {
            text-align: center;
            width: 85px;
            height: 45px;
            line-height: 45px;
            background-color: $bg-label;
            border-radius: 4px;
            font-size: 22px;
        }
    }
}

.project-sub-header .description {
    font-size: 15px;
}

.project__section { margin-bottom: 30px;}
.status-reason-wrapper {
    .btn {
        margin-bottom: 5px;
        float: left;
    }
    .status-desc {
        line-height: 18px;
        display: inline-block;
        max-width: 300px;
        height: 34px;
        vertical-align: middle;
        display: table-cell;
    }
}

@media only screen and (max-width: 1200px) {
    .status-reason-wrapper {
        .btn { float: none;}
        .status-desc { max-width: initial;}
    }
}

.list-project-report {
    .status-reason-wrapper .btn { float: none; margin-right: 0;}
    .status-reason-wrapper .status-desc {
        max-width: initial;
        height: auto;
        display: inline-block;
    }
}

.status-reason {
    color: $red;
    margin-left: 20px;
}

.status-deadline {
    font-weight: normal;
}

@media only screen and (max-width: 998px) {
    .phase-label { margin-bottom: 10px;}
    .status-current { margin: 0;}
}

#dashboard-menu { margin-top: 10px;}

@media only screen and (max-width: 767px) {
   #dashboard-menu { margin-top: 0;}
}
.report-progress-title {
    font-weight: bold;
    font-size: 15px;
    margin: 0 20px 0 0;
    .glyphicon {
        margin-right: 5px;
    }
}

.phase-description {
    .field-label {
        color: $text-table;
        font-weight: normal;
    }
}

.progress-step-wrapper { margin-top: 15px;}
.report-progress hr { margin-bottom: 15px;}

.project-summary-item {
    margin: 0 10px;

    //** hide/show collapse
    &.collapse {
      height: 230px !important;
      overflow: hidden;
      display: block;
      position: relative;
      &.in {
        height: auto !important;
      }
      &:after {
        content: "";
        width: 100%;
        height: 50px;
        background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
        background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
        background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }

    .item-border:first-child { margin: 0;}
    .item-border { min-height: 95px;}
}

.project-summary-footer {

  .btn {
    display: block;
    margin: 2em auto 0;
  }
}

.project-summary-item {
  .card {
    font-family: 'th_sarabun_new',"Thonburi", "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 18px;
    font-weight: 400;
    padding: 0.25em;
    margin-bottom: 1em;
    background: #fff;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.39);
    height: 100%;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
    overflow: hidden;
    &.large {
      padding: 1em;
      .card-title {
        font-size: 30px;
        padding: 0.5em;
        margin-top: 0;
      }
      .total-label { text-align: left; }
      .total-value {
        text-align: left;
        font-size: 50px;
      }
    }
    &.dark { background: #E5E5E5; }
    &.blue { background: #C9F7F2; }
  }
  .list-item {
    min-height: 65px;
  }

  .card-title {
    // padding: 0.5em;
    font-size: 22px;
    font-weight: bold;
    line-height: 1;
    font-style: italic;
  }

  .total-label  {
    font-size: 22px;
    line-height: 1em;
    // text-align: center;
  }
  .total-value {
    font-family: 'th_sarabun_newbold',"Thonburi", "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 38px;
    line-height: 1;
    font-weight: bold;
    // text-align: center;

  }
  table>tbody>tr>td {
    padding: 4px 10px;
    font-size: 20px;
  }
  table>tbody>tr>th.card-title{
    font-family: 'th_sarabun_newbold',"Thonburi", "Helvetica Neue",Helvetica,Arial,sans-serif;
    line-height: 1.2em;
    color: #000;
  }
  .year-area {
    .tab-pane {
      padding: 0.25em;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.39);
    }
  }
  .year-area-item {
    font-family: 'th_sarabun_new',"Thonburi", "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 22px;
  }
}

.d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
  }
}
