@font-face {
	font-family: 'icomoon';
	src:url('/static/fonts/icomoon.eot?-k0w6v3');
	src:url('/static/fonts/icomoon.eot?#iefix-k0w6v3') format('embedded-opentype'),
		url('/static/fonts/icomoon.ttf?-k0w6v3') format('truetype'),
		url('/static/fonts/icomoon.woff?-k0w6v3') format('woff'),
		url('/static/fonts/icomoon.svg?-k0w6v3#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"], [class*="iconfont-"] {
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-chevron-down:before {
	content: "\e600";
}
.icon-notification:before {
	content: "\e601";
	font-size: 20px;
}