/*
**  Reset General Bootstrap Style.
**/

body {
    font-family: "Thonburi", "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 15px;
    line-height: 22px;
}

a, .div-clickable, .view-all {
    color: $blue;
    text-decoration: underline;
    cursor: pointer;
    display: inline-block;
    &:hover, &:focus, &:active, &.active { 
        color: #fff;
        text-decoration: underline;
        background-color: $blue;
    } 
}

a.btn { text-decoration: none;}

p { margin: 5px 0;}

.btn { border-radius: 3px;}
.btn-small { font-size: 12px;}
.help-block { 
    color: #777; 
    font-size: 14px; 
}
.col-no-pad { padding: 0;}
.clearbox { clear: both;}
.close { line-height: 14px;}

.list-group { margin: 0;}

.list-group-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.list-group-item:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.list-group .list-group-item.has-border-top:first-child {
    border-top: 1px solid #ccc;
}

.list-inline li {
    display: inline-block;
}


// ##### Panel #####
.panel-heading { overflow: hidden;}
.panel-title { float: left;}
.panel-box label { margin-bottom: 0;}
.panel-box .help-block { margin-top: 3px; margin-bottom: 0;}
