/*
**  From General Style.
**/

input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"],
textarea.form-control {
  border: 1px solid $border;
}

// input[type="number"] { text-align: right;}

.form-group input[id*="-comments"] {
  background-color: #FFFFCC;
}

.form-group .input-left > input {
    text-align: left;
}

.comment-input input, .comment-input textarea { background-color: #FFFFCC;}

.form-control[readonly],
fieldset[disabled] .form-control, select[disabled].form-control, textarea[disabled].form-control {
    background-color: #f4f4f4;
    cursor: not-allowed;
    color: #444;
    &:focus, &:active {
      outline: 0;
      border-color: #e0e0e0;
      box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    }
}

label.radio-inline {
  font-weight: 13px;
  font-weight: bold;
  color: $text-label;
}

.form-actions {
  border: 1px solid $border;
  padding: 17px 27px;
  margin-bottom: 40px;
  background-color: white;
  border-top-color: #DDDDDD;

  > div[class*="col-"] {
    padding: 0;
  }
}

@media only screen and (max-width: 580px) {
  .form-actions > div[class*="col-"] > .btn {
    width: 100%;
    margin: 0 0 3px;
  }
}

.box--form-actions {
  border: none;
  padding: 17px 0;
  margin-bottom: 0;
  background-color: transparent;
}

.form-group--section {
  margin-bottom: 40px;
}

.not-input { 
  background-color: #e5e5e5; 
  cursor: not-allowed;
  border: 1px solid #e0e0e0;
}

.detail-field, .tab-pane {
  .label-field {
      display: inline-block;
      padding: 9px 10px 3px;
      background-color: #eee;
  }
}

.checkbox+.checkbox { margin-top: 10px;}
.checkbox .help-block { padding-left: 18px;}
/* 
** Prototype of generic form structure
*/

/* Beautiful label */
.tab-pane {
  .section-subheader {
    margin-bottom: 1.5em;
  }
  > .form-group {
    margin-bottom: 40px;

    > label { 
      margin-bottom: 15px;
      font-size: 18px;
    }
    > label .glyphicon-pencil {
      font-size: 13px;
      top: 0;
    }
    > label .glyphicon-info-sign {
      font-size: 20px;
      top: 4px;
    }
  }
  > .form-group.no-bottom { margin-bottom: 0;} 
  > .form-group.last { margin-bottom: 0;}
}

.modal-body .tab-pane {
  padding-top: 20px;
  .label-field { margin-bottom: 10px;}
  label { font-weight: normal;}
}

.addition-form-group { margin-top: 3em;}

.tab-no-child {
  > label + .form-group {
    margin-bottom: 10px;
  }
  > .form-group {
    > label {
      font-size: 15px;
    }
    [class*="col-"] {
      width: auto;
      line-height: 34px;
      input { width: 70px; }
    }
  }
  input,
  textarea { background-color: $bg-field; }
}

.label-edit {
  margin-bottom: 1.2em;
  font-size: 18px;
  > .glyphicon-pencil {
      font-size: 13px;
      top: 0;
      color: $text-icon;
  }
}

.label-primary {
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: bold;
  background-color: transparent;
}
/* ------------------- */

.form-with-delete-bar, .formset-item-wrapper {
  position: relative;
  padding: 20px 70px 20px 25px;
  border: 1px solid $border;
  border-radius: 3px;
  margin-bottom: 1em;
  background-color: $bg-form-with-delete-bar;
  .file-list { padding: 0; overflow: visible;}
  .btn-remove {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    width: 40px;
    border-radius: 0 3px 3px 0;
    color: #fff;
    .glyphicon {
      position: absolute;
      top: 10px;
      left: 12px;
    }
  }
}

.formset-item-wrapper:not(.form-with-delete-bar) .btn-remove {
  display: none !important;
}


.formset-header-item-wrapper {
    display: none;
}

.formset-as-table .formset-item-wrapper select[disabled] {
    border: none;
    padding: 0;
    margin: 0;
    outline: none;
    -webkit-appearance: none;
    box-shadow: none;
    cursor: text;
    font-weight: bold;
    background-color: transparent;
    word-wrap: break-word;
    white-space: normal;
    height: auto;
    display: inherit;
}


.formset-as-table .formset-item-wrapper textarea[disabled],
.formset-as-table .formset-item-wrapper textarea[readonly] {
    cursor: text;
    font-weight: bold;
}
.formset-as-table .formset-item-wrapper input[type=number] {
    text-align: right;
}

.formset-as-table .formset-header-item-wrapper {
    display: block;
}

.formset-as-table .formset-item-wrapper.formset-header-item-wrapper {
    background-color: #ddd;
}

.formset-as-table .formset-wrapper {
    display: table;
    border-collapse: collapse;
    width: 100%;
}
.formset-as-table .formset-item-wrapper {
    display: table-row;
    background-color: transparent;
}
.formset-as-table .formset-header-item {
    /*background-color: #efefef;*/
    font-weight: bold;
    text-align: center;
}
.formset-as-table .form-group, .formset-as-table .btn-remove {
    display: table-cell;
    vertical-align: middle;
    padding: 10px;
    margin: 0;
    border: 1px solid #333;
    position: static;
}
.formset-as-table .btn-remove {
    background-color: transparent;
    color: #333;
}
.formset-as-table .form-group label,
.formset-as-table .form-group .form-text {
    display: none;
}
.formset-as-table .radio label {
    display: inline-block;
}
.inline-radios .radio {
  display: inline-block;
  margin-right: 10px;
}
.table-radios > div {
  display: table;
  width: 100%;
  .radio {
    display: table-cell;
    text-align: center;
    .label-text {
      display: none;
    }
  }
}
.label-table-header {
  display: table;
  width: 100%;
}
.label-table-header-cell {
  display: table-cell;
}
.label-origin {
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: solid 1px #000;
}
.formset-as-table .form-group.formset-header-item .form-text {
    display: block;
}
.formset-as-table textarea, .formset-as-table input[type=text], .formset-as-table input[type=number],
.formset-as-table textarea:focus, .formset-as-table input[type=text]:focus, .formset-as-table input[type=number]:focus,{
    resize: none;
    border: none;
    border-radius: 0;
    background-color: transparent;
    padding: 0;
    margin: 0;
    height: auto;
}
.formset-as-table textarea, .formset-as-table input[type=text], .formset-as-table input[type=number] {
    box-shadow: none;
}
.formset-as-table textarea:focus, .formset-as-table input[type=text]:focus, .formset-as-table input[type=number]:focus, {
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}
.formset-as-table .select2-container {
    width: 100% !important;
    min-width: inherit;
}
.formset-as-table select:not([disabled]) {
    min-width: 60px;
}
.formset-as-table .formset-add-button {
    text-align: left;
    background-color: transparent;
    border: 0;
}

.formset-as-table .form-group.is-invalid {
    border: solid 2px #dc3545;
}
.formset-as-table {
    overflow-x: auto;
}

.table.table-bordered-dark {
    border-color: #000;
    tr, tr th, tr td {
      border-color: #000;
    }
    .tabel-header {
      background-color: #ddd;
    }
}

.radio-inline.small {
  width: 50px;
  height: 58px;
  border: 1px solid $border-radio;
  border-radius: 3px;
  padding: 7px 7px;
  text-align: center;
  margin-left: 0; 
  margin-right: 0.5em;
  margin-bottom: 0.5em;

  + .radio-inline { margin-left: 0; }

  input[type="radio"] {
    margin-left: 0;
    bottom: 10px;
    left: 16px;
  }
}
.radio-middle {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0.5em;
  margin-right: 18px;

  .radio-inline.input-left {
    width: 200px;
    height: 60px;
    vertical-align: middle;
    display: table-cell;
    padding-left: 30px;
    border: 1px solid $border-radio;
    border-radius: 3px;
    padding-right: 1em;

    input[type="radio"] {
      top: 50%;
      margin-top: -5px;
    }
  }
}

/* Plan list form */
.form-report-plan .form-group {
  .col-xs-1, .col-xs-11 {
    padding: 0;
  }
  .col-xs-1 { 
    text-align: center; 
    line-height: 34px;
  }
}

.innovation-type ul.errorlist {
  margin-top: 0;
}

.best_practice {
  margin-bottom: 0;
  label + .best_practice--group {
    margin-bottom: 18px;
  }
}
.best_practice--group {
  padding: 20px;
  border: 1px solid $border-radio;
  border-radius: 3px;
  input { margin-right: 17px; }
  label { margin-bottom: 0; }
}
.best_practice--first {
  padding-left: 35px;
  // margin-top: 15px;
}

@media only screen and (max-width: 580px) {
  .btn-color--send, .btn-color--reject { margin: 0;}
}

@media only screen and (max-width: 991px) {
  .form-control-wrapper { margin-bottom: 20px;}
}

// form Other Result
.beneficiary-report .form-group {
  // margin-bottom: 20px;
  > label { font-weight: bold;}
} 

// form environment-report
.environment-report {
  .form-control {
    margin-left: 20px;
    width: 90%;
  }
  .checkbox { margin-bottom: 20px;}
}

// form financial-report
.financial-report {
  border-bottom: 1px dashed #ccc;
  margin-bottom: 30px;
  padding-bottom: 30px;
  .label-field {
    display: inline-block;
    padding: 0;
    font-weight: bold;
    margin-bottom: 30px;
    font-family: "Thonburi", "Helvetica Neue",Helvetica,Arial,sans-serif;
    background-color: transparent;
    font-size: 16px;
  }
  .form-group { max-width: 600px;}
  .form-control { text-align: right;}
} 

.total-amount { font-weight: bold;}

.btn-close {
  @include border-box;
  width: 24px;
  height: 24px;
  border-radius: 15px;
  border: 3px solid #fff;
  background-color: #000;
  box-shadow: 1px 1px 3px #333;
  padding: 0;
  text-align: center;
  line-height: 18px;
  &:hover, &:focus, &:active { background-color: #333; border: 3px solid #fff;}
  .glyphicon.glyphicon-remove { 
    font-size: 13px; 
    margin: 0;
    color: #fff;
  }
}

.sector2-financial-report input[type="number"]:not([readonly]) {
    background-color: #FFFFCC;
}

// Start-End Time Event
.input-time {
  .input-number-wrapper, .colon-time, .unit-time { display: inline-block;}
  .input-number-wrapper {
    width: 80px;
    display: inline-block;
  } 
  .colon-time {
    margin: 0 5px;
  }
  .unit-time { margin-left: 5px;}
}

.row-budget {
  overflow: hidden;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  margin-bottom: 10px;
  .form-group { margin: 0;}
  label { padding: 6px 0;}
}

.row-summary-budget {
  overflow: hidden;
  padding: 10px 5px;
  background-color: #eee;
  label { margin: 0;}
}

.summary-amount {
  padding: 0 12px;
}

// btn 
.btn-submit { min-width: 150px;}
@media only screen and (max-width: 768px) {
  .btn-submit { width: 100%;}
}

.btn-link {
  padding: 0;
  font-size: 12px;
  margin-left: 5px;
  border: 0;
}

.btn-transparent {
  background-color: transparent;
  padding: 6px 0;
  border: 0;
  &:hover, &:focus, &:active { 
    background-color: transparent;
    border: 0;
    color: $blue;
    text-decoration: underline;
  }
}

@media only screen and (max-width: 768px) {
    .account-box .text-right {
        text-align: left;
    }
    .account-box {
      .btn { width: 100%; margin-bottom: 10px;}
      .btn-transparent { text-align: left;}  
    }
}

@media only screen and (min-width: 768px) {
    .account-box .input-group.manual {
        margin-top: 10px;
        margin-bottom: 0;
    }
}

// Choose row
.list-role  {
  margin-top: -15px;
  margin-bottom: -15px;
}

.list-role li a {
  display: inline-block;
  width: 100%;
  padding: 15px;
  border-bottom: 1px solid #ddd;
  text-decoration: none;
  position: relative;
  &:hover, &:focus, &:active {
    .help-block, .glyphicon { color: #fff;}
  }
}

.list-role {
  .glyphicon {
    position: absolute;
    right: 15px;
    font-size: 32px;
    top: 26px;
    color: #ddd;
  }
}

.list-role li.last a { border-bottom: 0;}

// Input Group 
.input-group .label-text {
    display: inline-block;
    padding: 6px 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: #555;
    text-align: left;
}

// Login box
.panel-box { margin-top: 30px;}
.account-box, .dialog-box {
  label, input {display: table-cell; vertical-align: top;}
  .input-group { margin: 0 0 15px; width: 100%;}
  input {
      @include border-box;
      height: 34px;
      padding: 5px 10px;
      border-radius: 0 5px 5px 0;
      width: 100%;
  }
  label { height: 34px;}
  .form-btn { margin-bottom: 0;}
  .panel-body { padding: 2em 20px;}
}

.dialog-box {
  .panel-body { padding: 1em;}
}

.login-box {
    label { width: 10%;}
    .input-group { width: 100%;}
}

@media only screen and (max-width: 768px) {
  .account-box label {
    height: auto;
  }
}

.panel-body .action-group { margin: 0;}

.form-group .select2-container { width: 100% !important;}

.form-follow-up-teamleader {
  max-width: 500px;
  label { font-size: 13px;}
}

.select2-container--default .select2-selection--single {
  .select2-selection__placeholder, .select2-selection__rendered {
    font-size: 13px;
  }
} 

.select2-results__option {
  font-size: 12px;
}
span.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #eee;
  color: #444;
}

.select2-dropdown--below {
  max-width: 500px;
}

// .comment-input { max-width: 870px;}
.comment-wrapper { margin-top: 5px;}
.comment-wrapper label { font-size: 14px;}

.report-form {
  label { font-weight: normal;}
  label.label-primary { font-weight: bold; color: #19988b;display: block;}
}



.comment-by { 
  margin: 5px 0;
  padding: 5px;
  background-color: #FFFFDD;
}

.activity-text-empty { margin-bottom: 10px;}

// form-report-objective-activity
.form-report-objective-activity {
  .detail-field  .label-field {
    font-family: "Thonburi", "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 15px;
    font-weight: normal;
    padding: 0;
    background-color: transparent;
  }
  .dl-horizontal { font-size: 14px;}
}

.show-on-collapse {
  display: none;
}
.form-report-objective-activity.activity-collapse {
  cursor: all-scroll;

  .activity-group-wrapper, .btn-remove {
    display: none;
  }
  .name {
    margin-bottom: 0;
  }
  .show-on-collapse {
    display: inline-block !important;
  }

}

// tab-pane 
.tab-pane  {
  .help-block { margin: 0 0 10px;}
}

.form-report-innovation {
  .form-group { margin-bottom: 25px;}
  .form-group.last { margin-bottom: 0;}
  .form-group .file-list {
      li.file-list--item, li.file-list--upload { 
        margin-bottom: 5px;
        min-height: initial;
      }
    }
}

.tab-pane.progress-report > .form-group {
  > label { margin-bottom: 5px;}
  &.last > label { margin-bottom: 15px;}
}

@media only screen and (max-width: 991px) {
  .tab-objectives li {
    width: 30%;
    float: left;
    border: 0;
    background-color: #fff;
    margin-bottom: 4px;
    margin-right: 1%;
    margin-left: 1%;
    border: 1px solid #ccc;
    margin: 0 5px 5px 0;
    text-align: center;
  }
  .tab-objectives.nav-pills--1 >  li {
    border-radius: 4px;
  }
  .tab-objectives.nav-pills--1 >  li a { margin: 0;}
  .tab-objectives.nav-pills--1 >  li.active { border: 1px solid #ccc;}
}

@media only screen and (max-width: 620px) {
  .tab-objectives li {
    width: 100%;
    margin: 0 0 5px;
  }
}

// form-assign
.form-assign {
  .select2-container { width: 100% !important;}
  .label-selector { max-width: 170px;}
  .select2-container--default .select2-selection--single .select2-selection__arrow { top: 5px;}
  .select2-container--default .select2-selection--single .select2-selection__rendered { line-height: 32px;}
  .select2-container .select2-selection--single { height: 34px;}
  .selector { padding-right: 0;}
}

.label-selector label {
  height: 34px;
  vertical-align: middle;
  display: table-cell;
  min-width: 170px;
}

.form-assign  .row-item { padding: 10px 20px;}

@media only screen and (max-width: 991px) {
  .form-assign {
    .selector-wrapper {
      margin-top: 10px;
    }
  }
  .label-selector label {
    height: auto;
  }
}

.role-applicant { font-size: 14px;}

.activities-select-list {
  .media-right {
    display: block;
    clear: both;
    margin-top: 3px;
    margin-left: 10px;
  }
  .media-right .btn {
    margin: 5px;
  }
}


.form-field-course_sections .formset-as-table > div {
    min-width: 790px;
}