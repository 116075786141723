.form-search-wrapper {
    position: relative;
    padding: 0;
    margin: 8px 0;
    color: #fff;
    .btn {
        background-color: transparent;
        border: 0;
        position: absolute;
        left: 0px;
        top: 0;
    }
    .form-control {
        padding-left: 35px;
        background-color: #39B9A0;
        border: 0;
        color: #fff;
    }
    ::-webkit-input-placeholder {color: #bbeae1;}
    ::-moz-placeholder { color: #bbeae1;}
    :-ms-input-placeholder { color: #bbeae1;}
    :-moz-placeholder { color: #bbeae1;}
}

.form-search { display: inline-block; width: 390px;}

.icon-search-wrapper {
    #search {
        background-color: transparent;
        border: 0;
        height: 50px;
        margin: 0 0 0 10px;
    }
    .glyphicon-search {
        color: #fff;
        font-size: 18px;
    }
}

.search-collapse-wrapper {
    border: 0;
    margin: 0;
    width: 100%;
    clear: both;
    overflow: hidden;
    .form-search-wrapper { margin: 0 0 10px;}
    .form-search { width: 100%;}
}

.form-search-md { float: left;}

.user-panel {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 10px;
    float: right;
    .dropdown-menu {
        left: auto;
        right: 0;
        padding: 0;
    }
    .no-link { padding: 3px 20px;}
    .dropdown { float: right;}
}

.topbar { width: 100%;}
.user-panel > .user-link-action, .dropdown-search {
    @include border-box;
    height: 50px;
    display: table;
}

@media only screen and (max-width: 767px) {
    .user-panel > .user-link-action { width: 18px;}
    .user-panel > .login-link { width: auto;}
}

.link-user-toggle, .user-link-action > a {
    color: #fff;
    text-decoration: none;
    &:hover, &:active, &:focus, &.active { color: #fff;text-decoration: none;}
}

.user-link-action { margin-left: 10px;}

.link-user-toggle { font-size: 12px;}
.link-user-toggle > .glyphicon { font-size: 16px;}
.full-name-link { line-height: 16px;}

.site-name {
    font-size: 22px;
    padding: 0;
    float: none;
    color: #fff;
    &:hover, &:active, &:focus, &.active { color: #fff;}
}

.site-name-wrapper {
    @include border-box;
    display: table;
    height: 50px;
    margin-right: 10px;
}
.topbar a { 
    text-decoration: none;
    &:hover, &:active, &:focus, &.active {
        background-color: transparent;
    }
}

@media only screen and (max-width: 480px) {
    .site-name-wrapper { max-width: 165px; padding: 15px 0;}
}

// Header
@media only screen and (max-width: 768px) {
    body { padding-top: 0;}
    .navbar-fixed-top {
        position: static;
    }
}

.btn-dashboard-menu {
    margin: 8px 0 0 10px;
}

.navbar-inverse .navbar-toggle {
    border-color: #39B9A0;
    &:hover, &:focus { background-color: #39B9A0;}
}

.nav-dashboad {
    margin-bottom: 10px;
    >li> a {
        color: white;
        border-radius: 4px;
        text-decoration: none;
    }
    > li > a:hover,
    > li > a:focus,
    > li.active > a,
    > li.active > a:hover,
    > li.active > a:focus {
        background-color: $bg-nav-dashboard;
    }
    .glyphicon {
        font-size: 18px;
        top: 4px;
        margin-right: 8px;
    }
}

@media only screen and (max-width: 767px) {
    .nav-dashboad {
        li { width: 100%;}
        >li+li { margin: 0;}
        > li > a { border-radius: 0;}
    }
}
// dropdown nav Dashboard
.nav-dashboad li.dropdown.open {
     > a, > a:focus, > a:hover {
        background-color: $bg-nav-dashboard;
        border-radius: 4px 4px 0 0;
    }
}

.nav-dashboad li.dropdown.active > a {
    background-color: $bg-nav-dashboard;
}

.nav-dashboad .dropdown-menu:before {
    content: none;
}

.nav-dashboad .dropdown-menu {
    margin: 0;
    padding: 0;
    border: 0;
    border-radius: 0 4px 4px 4px;
    background-color: $bg-nav-dashboard;
    li a {
        padding: 6px 10px;
        color: #fff;
        text-decoration: none;
        &:hover, &:focus {
            background-color: $bg-nav-dashboard-active;
        }
    }
    li.active a {
        background-color: $bg-nav-dashboard-active;
    }
    li:first-child a { border-radius: 0 4px 0 0;}
    li.last a { border-radius: 0 0 4px 4px;}
}

.nav-dashboad .glyphicon-chevron-down {
    font-size: 12px;
    top: 3px;
    margin: 0 0 0 2px;
}

@media only screen and (max-width: 767px) {
    .nav-dashboad .dropdown-menu {
        width: 100%;
        position: static;
        box-shadow: none;
        border-radius: 0;
        background-color: transparent;
        li a {
            padding-left: 46px;
            border-radius: 0;
            &:hover, &:focus {
                background-color: $bg-nav-dashboard;
            }
        }
        li.active a { background-color: $bg-nav-dashboard;}
        li:first-child a, li.last a { border-radius: 0;}
    }
    .nav-dashboad .glyphicon-chevron-down { float: right;}

}


.dropdown-menu:before {
    content: '\00a0';
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 11px solid #f5f5f5;
    position: absolute;
    top: -32px;
    right: 0;
    width: 0;
}

.notification-wrapper {
    float: left;
    height: 50px;
    display: table;
    margin-right: 10px;
}

.noti-badge {
    background-color: #FF3D3D;
    border-radius: 50px;
    padding: 5px 0;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    font-size: 10px;
}

.noti-count {
    @include border-box;
    width: 18px;
    height: 18px;
    border: 1px solid #fff;
    border-radius: 10px;
    font-size: 8px;
    line-height: 15px;
    position: absolute;
    text-align: center;
    bottom: 10px;
    right: -7px;
    background-color: #19988b;
}

@media only screen and (max-width: 768px) {
    .notification-wrapper {
        position: absolute;
        left: -10px;
        z-index: 11;
        bottom: 7px;
        height: auto;
    }
}

.dropdown-header {
    background-color: #f5f5f5;
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid #eee;
    margin: 0;
    padding: 10px;
    .dropdown-title {
        font-size: 14px;
        font-weight: bold;
        color: #444;
        margin: 0;
    }
}

.dropdown-header .list-item {
    margin-top: 5px;
}

.dropdown-header,
.dropdown-body {
    .list-item a { 
        padding: 7px 10px;
        background-color: #fff;
        border-radius: 0;
        display: block;
        border-bottom: 1px solid #eee;
        &:hover, &:focus, &.active {
            text-decoration: none;
            background-color: #eee;
            color: $blue;
        }
    }
}

.dropdown-header{
    .list-item a {
        padding: 10px 10px;
    }
}

.dropdown-footer {
    padding: 10px;
    border-top: 1px solid #eee;
}

.dropdown-footer a {
    font-size: 12px;
    &:hover, &:focus, &.active {
        color: $blue;
        text-decoration: underline;
    }
}

.dropdown ul li {
    list-style: none;
}

.dropdown-wrapper {
    @include border-box;
    width: 250px;
    box-shadow: 0 2px 2px 0 #bbb;
    border: 0; 
    border-top: 1px solid #eee;
    margin: 0;
    .list-item { 
        font-size: 12px;
    }
    .list-item .glyphicon { margin-right: 10px;}
}

.list-notice a {
    color: #333;
} 

.dropdown-wrapper .description {
    font-size: 12px;
    display: block;
    margin-top: 5px;
}

/* Tab panel */
.navbar {
    margin-bottom: 0;
    background-color: $bg-header;
    border: none;
    border-radius: 0;
    .navbar-brand,
    .navbar-nav > li > a { color: white; }
}
.nav-tabs {
    border-bottom: 0;
    margin-bottom: -2px;
}
.nav-tabs > li {
    border-top: 5px solid transparent;
    margin-right: 2px;
}
.nav-tabs > li > a {
    border-radius: 0;
    margin-right: 0;
    color: $text-default;
    padding: 10px 20px;
}
.nav-tabs > li > a:hover {
    background-color: transparent;
    border-color: transparent transparent transparent transparent;
}

.nav-tabs > li.active {
    a, > a:focus, > a:hover {
        // border-top: 0;
        border-bottom: 0;
        border-left: 1px solid $border;
        border-right: 1px solid $border;
        cursor: pointer;
    }
}

.nav-tabs-border_radio > li.active {
    a, > a:focus, > a:hover {
        border-left: 1px solid $border-radio;
        border-right: 1px solid $border-radio;
    }
}

.nav-tabs-form.nav-tabs > li {
    margin-bottom: -2px;

}
.nav-tabs-form.nav-tabs > li.active {
    border-top: 5px solid $border-active; 
    border-bottom: 0;
    border-radius: 3px 3px 0 0;
    a {
        border-right: 1px solid #e5e5e5;
        border-left: 1px solid #e5e5e5;
        border-top: 0;
        text-decoration: none;
    }
}

@media only screen and (max-width: 1200px) {
    .nav-tabs-form.nav-tabs > li {
        font-size: 14px;
        > a { padding: 10px 13px;}
    }
}

@media only screen and (max-width: 991px) {
    .nav-tabs-form {
        margin: 0 -1%;
    }

    .nav-tabs-form.nav-tabs > li {
        width: 48%;
        float: left;
        border: 0;
        background-color: #fff;
        margin-bottom: 4px;
        margin-right: 1%;
        margin-left: 1%;
        border-radius: 4px;
        a { border: 0; border-radius: 4px;}
        border: 1px solid #ccc;
    }

    .nav-tabs-form.nav-tabs > li.active {
        border: 0;
        // border: 1px solid #ccc;
        // border-bottom: 1px solid #ccc;
        a { 
            border: 0;
            background-color: $blue;
            color: #fff;
        }
    }

    .nav-tabs-form.nav-tabs > li:nth-child(2n-1) { clear: both;}
}

@media only screen and (max-width: 480px) {
    .nav-tabs-form.nav-tabs > li {
        width: 100%;
        margin: 0 0 4px;
    }
}

.navbar-inverse .navbar-nav>.open>a,
.navbar-inverse .navbar-nav>.open>a:focus,
.navbar-inverse .navbar-nav>.open>a:hover {
    background-color: #444;
}

/* tab-content in tab-content */
.tab-content.tab-full-width {
    margin-left: -25px;
    margin-right: -25px;
    padding: 25px 25px 0 25px;
    border-top: 1px solid $border;
}

// .nav-tabs-overview { margin-bottom: 10px;}
.nav-tabs-overview {
    padding-bottom: 10px;
    margin: 20px 0 10px;
}
.nav-tabs-overview li { margin-bottom: 5px; margin-right: 10px;}
.nav-tabs-overview > li >   a { 
    text-decoration: none;
    color: #333;
    padding: 5px 20px;
    background-color: #eee;
    border-radius: 4px;
    &:hover, &:active, &:focus {
        background-color: $orange;
        color: #fff;
    }
}
.nav-tabs-overview li.active a {
    background-color: $orange;
    color: #fff;
    &:hover, &:active, &:focus {
        background-color: $orange;
    }
}

.icon-search-wrapper {
    float: left;
    .middle {
        height: 50px;
    }
}
