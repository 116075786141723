.modal {
  .tab-pane {
    > div {
      clear: both;
      padding: 10px 20px;
    }
    .alert {
      padding: 5px 15px;
      margin-top: 5px;
      font-size: 13px;
    }
  }
}

.modal-body.no-padding {
	.list-group-item:first-child { border-top: 0;}
}

.modal-content {
    box-shadow: 0 3px 6px rgba(0,0,0,.4);
    border-radius: 4px;
}

.modal-content .list-group-item {
    border-color: #e0e0e0;
}

.modal-header { padding: 15px;}

.modal-header .modal-title {
    font-size: 28px;
    line-height: 32px;
}

.modal-header .close {
    width: 18px;
    height: 18px;
}

.modal-body .list-group-item { padding: 15px;}

.modal-body {
  .list-group-item .description { margin-top: 5px;}
}

.modal-footer.text-left { text-align: left;}

.modal .btn-add-activity { margin-bottom: 20px;}