/*
** Font Face
*/

@font-face {
  font-family: 'th_sarabun_new';
  src:  url("/static/fonts/th-sarabun-new.eot");
  src:  url("/static/fonts/th-sarabun-new.eot?#iefix") format("embedded-opentype"),
        url("/static/fonts/th-sarabun-new.woff") format("woff"),
        url("/static/fonts/th-sarabun-new.ttf") format("truetype"),
        url("/static/fonts/th-sarabun-new.svg#th_sarabun_newregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
    font-family: 'th_sarabun_newbold';
    src: url('/static/fonts/thsarabunnew_bold.eot');
    src: url('/static/fonts/thsarabunnew_bold.eot?#iefix') format('embedded-opentype'),
         url('/static/fonts/thsarabunnew_bold.woff') format('woff'),
         url('/static/fonts/thsarabunnew_bold.ttf') format('truetype'),
         url('/static/fonts/thsarabunnew_bold.svg#th_sarabun_newbold') format('svg');
    font-weight: normal;
    font-style: normal;

}

h1, h2, h3, h4,
.navbar-brand,
.font {
  font-family: 'th_sarabun_new',"Thonburi", "Helvetica Neue",Helvetica,Arial,sans-serif;
}

h1, h2, h3 {
  font-family: 'th_sarabun_newbold',"Thonburi", "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-weight: 100;
}
h1 { font-size: 30px;}
h2 { font-size: 27px; line-height: 27px;}

.font-normal {
  font-family: "Thonburi", "Helvetica Neue",Helvetica,Arial,sans-serif;
}

.font-th {
  font-family: 'th_sarabun_new',"Thonburi", "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 20px;
  strong {
    font-family: 'th_sarabun_newbold',"Thonburi", "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight: 100;
  }
}

.font-bold {
  font-family: 'th_sarabun_newbold',"Thonburi", "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-weight: 100;
}

.font-heading {
  font-family: 'th_sarabun_new',"Thonburi", "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 28px;
}

.font-small {
  font-size: 13px;
}

.field-label { font-weight: bold;}

.description {
    color: #777;
    font-size: 13px;
    line-height: 20px;
}

.text-empty { color: #888;}