@import "mixin.scss";
@import "icofonts.scss";
@import "color.scss";
@import "font.scss";
@import "reset.scss";
@import "base.scss";
@import "topbar.scss";
@import "form.scss";
@import "file-upload.scss";
@import "dashboard.scss";
@import "modal.scss";
@import "datepicker.scss";
@import "calendar.scss";

body {
    color: $text-default;
    background-color: $bg-body;
}

@media only screen and (max-width:768px) {
    body { padding-top: 0;}
}

textarea {
    resize: none;
}

.header-inline {
    display: inline-block;
    margin-right: 10px;
    + span { color: $text-icon; }
}

.inline-block {
    display: inline-block;
}

.tab-top_layer {
    z-index: 2;
    margin-top: 30px;
}

.alone-right {
    margin-top: 15px;
    margin-bottom: 15px;
    margin-right: 15px;
    float: right;
}

@media only screen and (max-width: 76ึpx) {
    .alone-right { float: none; margin-left: 15px;}
}

.table-cell {
    display: table-cell;
    vertical-align: middle;
}

.middle {
    display: table-cell;
    vertical-align: middle;
}

.form-margintop { margin-top: 20px;}
.no-bottom { margin-bottom: 0;}
.overflow { overflow: hidden;}
.no-border { border: 0;}
.no-box-shadow { box-shadow: none;}
.no-padding { padding: 0;}
.header-has-padding, .has-padding { padding: 20px 25px;}

@media (max-width: 767px) {
    .has-margin-xs { margin-bottom: 20px;}
}


.link-list { list-style: none; padding: 0; margin: 0;}
.link-list li {
    display: inline-block;
    margin-right: 20px;
    &.active a, a.active { text-decoration: underline;}
}

@media only screen and (max-width: 767px) {
    .link-list li {
        display: inline-block;
        margin-right: 2%;
        width: 44%;
        margin-bottom: 10px;
        border-bottom: 1px solid #eee;
        padding-bottom: 10px;
    }
    .link-list li a { padding: 0 2px;}
} 

.header-content .link-list { font-size: 14px;}

@media only screen and (max-width: 991px) {
    .xs-margin-top {
        margin-top: 10px;
    }    
}

/*
** Custom Label
*/
.label-default.label-tag {
    background-color: $bg-label;
    font-size: 12px;
    padding: 8px 16px;
    margin: 0 10px 10px 0;
    font-weight: normal;
    display: inline-block;
    .glyphicon {
        margin-right: 10px;
    }
}
/*****************/

/*
** box
*/
.box {
    padding: 0;
    background-color: white;
    border: 1px solid $border-radio;
    > .col-xs-12 {
        padding: 0 20px;
    }
}
.box--no_heading {
    margin-top: 30px;
}
.box--bottom {
    margin-bottom: 30px;
}
.box-with-pad {
    padding: 15px;
}
.box-form {
    margin-top: 30px;
    margin-bottom: 30px;
    padding-top: 20px;
    border: 1px solid $border;
}
/*****************/
.header-wrapper {
    background-color: $bg-header;
    .header-content {
        background-color: white;
        padding: 20px;
        &.header-content--project__detail {
            padding-bottom: 0;
        }
        &.header-content-with_border_bottom {
            border-bottom: 1px solid $border-header-bottom;
        }
    }

    .nav-tabs>li>a {
        &:hover, &:active, &.active {
            text-decoration: underline;
        }
    }
    h2 + span { margin-right: 2em; }
}
.header-content--project__detail { border-bottom: 1px solid $border-header-bottom;}
.header-content.no-padding-top { padding-top: 0;}
.body-content {
    box-shadow: 0 0 2px 0 #bbb;
    background-color: #fff;
    overflow: hidden;
    margin-bottom: 30px;
    border: 0;
    clear: both;
    .list-group-item {
        border: 0;
        border-bottom: 1px solid $border-header-bottom;
        margin: 0;
    }
    .list-group-item:first-child {
        border-top: 0;
    }
}

.tab-pane { clear: both;}

.static-body-content { padding: 20px 25px;}
.static-body-content h1 {margin-top: 0;}

.breadcrumb {
    padding: 0 0 15px;
    margin: 0;
    background-color: transparent;
    .glyphicon { color: $text-table; font-size: 9px;}
}

.nav-tabs-xs > li > a {
    &:hover, &:active, &.active {
        text-decoration: underline;
    } 
}

.nav-tabs-xs > li.active a {
   &:hover, &:active, &.active {
       text-decoration: none;
   } 
}

.header-inline .description {
    @extend .font-normal;
}

@media only screen and (max-width: 767px) {
    .header-inline .description { display: block;}
}

.filter {
    margin-top: 10px;
    > span {
        line-height: 34px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
    }
}

.indicator__title {
    padding: 10px 0 0 25px;
}

#link-collapse {
    @include border-box;
    margin: 0 20px;
    padding: 10px 0 0;
    display: block;
    &:hover, &:focus, &:active, &.active { 
        color: #888;
        text-decoration: none;
        background-color: transparent;
    } 
}

.indicator__filter {
    padding: 10px 0 10px 25px;
    float: left;
    min-width: 200px;
}

.textinput-indicator__filter .indicator__select_wrapper { margin-left: 10px;}

// .indicator__filter:first-child + .indicator__filter + .indicator__filter { clear: both;}

// .academic-staging-filter .indicator__filter:first-child + .indicator__filter + .indicator__filter.indicator__action { clear: none;}

@media only screen and (max-width: 767px) {
    .indicator__action {
        clear: both;
    }
    .academic-staging-filter .indicator__filter:first-child + .indicator__filter + .indicator__filter.indicator__action { clear: both;}
}

// .indicator__filter_select {
//     position: relative;
// }
@media only screen and (min-width: 768px) and (max-width: 992px) {
    .indicator__filter_select { width: 50%;}
    .indicator__filter_select.clear { clear: both;}
    .indicator__filter.clear { clear: both;}
}

@media only screen and (max-width: 767px) {
    .indicator__filter_select {
        clear: both;
    }
    #link-collapse {
        border-bottom: 4px solid #eee;
        padding: 10px 0;
    }
}

.indicator__filter label {
   line-height: 34px;
   float: left; 
   font-size: 13px;
}

.indicator__select_wrapper { 
    position: relative;
    float: left;
    .icon-chevron-down {
        position: absolute;
        right: 0;
        top: 20px;
        font-size: 19px;
        line-height: 0;
    }
}

.btn__filter, .dropdown__filter, .indicator__filter_date input {
    border: 0;
    border-bottom: 1px solid #ccc;
    border-radius: 0;
    padding: 0 12px;
    line-height: 34px;
    box-shadow: none;
    -webkit-appearance: initial;
    -moz-appearance: initial;
}

.datepicker-dropdown.datepicker-orient-top:before {
    top: -31px;
    left: 5px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 9px solid #ccc;
}

.dropdown__filter {
    display: inline-block;
    margin-left: 5px;
    width: auto;
    padding: 0 25px 0 6px;
    width: 200px;
}

.btn__filter--indicator {
    line-height: 25px;
}
.dropdown-menu-keep_right {
    left: auto;
    right: 0;
}

.dropdown__filter {
    select { width: auto;}
}

.header-sub-content {
    width: 100%;
    float: left;
    background-color: #fff;
    margin-bottom: 2em;
    box-shadow: 0 2px 2px 0 #bbb;
    .item {
        // padding: 0 25px 25px 25px;
        // border-right: 1px solid $border-radio;
        height: 100%;
    }
    h3 {
        font-size: 20px;
        position: relative;
        background-color: $bg-form-with-delete-bar;
        margin-top: 0;
        margin-bottom: 10px;
        // margin-left: -25px;
        // margin-right: -25px;
        padding: 8px 25px;
    }
    > div:last-child .item { border-right: 0;}
    .list-group-item {
        border: 0;
        border-top: 1px solid $border-header-bottom;
    }
}

.header-sub-content__column {
    width: 20%;
    height: 175px;
    float: left;
    h3.arrow-step { padding: 0 25px; margin-bottom: 0;}
    @media (max-width: 991px) {
        width: 100%;
        height: auto;
        .item {
            border-right: none;
            border-bottom: 1px solid $border-radio; 
        }
        h3.next-arrow:before,
        h3.next-arrow:after { display: none; }
    }
}

.header-step-content { 
    border-top: 2px solid #eee;
    position: relative;
}

.header-step-content .item { padding-bottom: 0;}

.step-content-wrapper {
    padding:0 25px;
    border-right: 1px solid $border-radio;
    min-height: 98px;
}

.last .step-content-wrapper { border-right: 0;}

.arrow-step {
    position: relative;
    .glyphicon-chevron-right {
        font-size: 30px;
        position: absolute;
        right: -15px;
        z-index: 2;
        top: 8px;
    }
}

.step-content-wrapper {
    margin: 10px 0 0;
    .field-content {
        margin: 0 0 10px;
        font-size: 15px;
    }
    .text-icon { display: block;}
    .div-clickable { display: inline-block;}
}

.header-wrapper h2,
.project-header h1{
    margin-top: 0;
    margin-bottom: 5px;
}

.project__detail-summary {
    background-color: #fff;
    padding: 25px 0;
    border-top: none;
}
.project__detail-summary--label {
    padding: 0 10px;
    > .project-label-wrapper {
        padding-left: 15px;
    }
}

.project-header .filter { margin: 0;}

@media only screen and (max-width: 768px) {
    .project-header .filter {
        clear: both;
        float: none !important;
        margin-top: 20px;
    }
}

.header-step-content h3.arrow-step {
    padding: 0 25px 0 70px;
}

.step-count {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    border: 4px solid #555;
    text-align: center;
    font-weight: bold;
    color: #555;
    text-align: center;
    display: inline-block;
    font-size: 32px;
    position: absolute;
    left: 20px;
    top: 5px;
    background-color: #fff;
}

.arrow-step .step-title {
    height: 50px;
}

.line-step {
    position: absolute;
    width: 5px;
    background-color: #555;
    left: 38px;
    top: 10px;
    bottom: 0;
    display: none;
}


@media only screen and (min-width: 991px) and (max-width: 1200px) {
    .step-count {
        width: 30px;
        height: 30px;
        font-size: 25px;
    }
    .header-step-content h3.arrow-step { padding-left: 60px;}
}

@media only screen and (max-width: 991px) {
    .glyphicon-chevron-right { display: none;}
    .header-step-content .item {
        background-color: transparent;
        padding-left: 70px;
        margin: 0px;
        border: 0;
    }
    .header-step-content {
        border-right: 0;
        h3.arrow-step { 
            background-color: transparent; 
            padding-left: 0;
            margin: 0;
        }
    }
    .step-count { left: -50px;}
    .step-content-wrapper { 
        margin: 0; 
        min-height: initial; 
        border-right: 0;
        padding: 0;
    }
    .line-step { display: block;}
}

.item-border {
    border: 1px solid $border-radio;
    padding: 10px 15px;
    margin: 15px 0;
    &:first-child {
        margin-left: 10px;
    }
    p { font-size: 14px; }
    p + p {
        display: inline-block;
        font-size: 30px;
        line-height: 30px;
        margin-bottom: 0;
    }
}

.progress {
    margin-bottom: 0;
    height: 18px;
    background-color: $bg-label;
    border-radius: 5px;
    .progress-bar {
        background-color: $green;
    }
}

a.print-btn {
    width: 88px;
    border: 1px solid #ccc;
    text-decoration: none;
    color: #666;

    .glyphicon { 
        font-size: 16px;
        margin-bottom: -1px;
    }
}

a.example-btn {
    width: 190px;
    margin-right: 15px;
    border: 1px solid #ccc;
    text-decoration: none;
    color: #666;

    .glyphicon {
        font-size: 16px;
        margin-bottom: -1px;
    }
}

.phase-label { width: 120px; }

.list-group-item { padding: 20px;}
.list-group-item {
    .btn-color--blue {
        width: 190px;
    }
}
.line {
    margin-bottom: 10px;
    span, .btn { margin-right: 10px; }
}

.last-line { margin-bottom: 0;}

.btn-send-report {
    border: 1px solid $border;
    .text-sub { font-size: 12px;}
    .glyphicon { 
        font-size: 20px;
    }
    .media-left { padding-right: 0;}
    &:hover, &:focus, &:active { color: #fff;}
}

.report-progress--group {
    width: 100%;
    .btn {
        height: 50px;
        padding-left: 30px;
        border-color: $border-progress-default;
        &.text-left { text-align: left; }
        &.text-right { text-align: right; }
        &:hover {
            cursor: default;
            box-shadow: none;
            border-color: $border-progress-default;
        }
        &.btn-step--start {
            padding-left: 12px;
        }
    }
    label, p { margin-bottom: 0; }
    .btn-step {
        display: table;
        height: 51px;
        margin-left: 0 !important;
        background-color: $bg-progress-default;
        .field-label { margin: 0; font-weight: normal;}
        .field-content {
            color: #666;
            font-size: 12px;
        }
        &:before {
            border-left: 20px solid $border-progress-default;
        }
    }
    .btn-step--next {
        &:before,
        &:after {
            content: "";
            position: absolute;
            top: -1px;
            bottom: -1px;
            right: -20px;
            width: 0;
            z-index: 3;
            border-top: 25px solid transparent;
            border-right: 0 solid transparent;
            border-bottom: 25px solid transparent;
            // border-left: 20px solid $border-progress-pass;            
        }
        &:after {
            right: -19px;
            border-left: 21px solid $bg-progress-default;
        }
    }
    .btn-step--pass {
        // background-color: $bg-progress-pass;
        border-color: $border;
        &:before {
            border-left: 21px solid $border-progress-pass;
        }
        &:after {
            border-left: 21px solid $bg-progress-pass;
        }
    }

    .btn-step-last {
        padding-left: 30px;
        width: 70px;
    }
    .btn-step--current {
        background-color: $bg-progress-current;
        border-color: $border;
        color: #fff;
        &:before {
            border-left: 20px solid $border;
        }
        &:after {
            border-left: 20px solid $bg-progress-current;
        }
        .field-content { color: #fff;}
    }
}

@media only screen and (max-width: 992px) {
    .report-progress--group {
        margin: 10px 0;
    }
    .btn-step { margin-bottom: 5px;}
}

.glyphicon-ok-circle {
    color: #6EA84A;
}
.btn-step .glyphicon-ok-circle { display: none;}
.btn-step--pass .step-wrapper {
    padding-left: 37px;
    position: relative;
    .glyphicon-ok-circle {
        display: block;
        position: absolute;
        left: 0;
        font-size: 32px;
    }
}

@media only screen and (max-width: 690px) {
    .report-progress--group {
        width: auto;
    }
    .report-progress--group .btn {
        display: block;
        float: none;
        clear: both;
        width: auto;
        border-radius: 3px;
        padding-left: 12px;
        margin-bottom: 30px;
    }
    .report-progress--group >.btn:first-child:not(:last-child):not(.dropdown-toggle) {
        border-radius: 3px;
    }
    .report-progress--group .btn:before, .report-progress--group .btn:after { content: none;}
    .report-progress--group .btn:after {
        content: "\e094";
        display: inline-block;
        font-family: 'Glyphicons Halflings';
        font-style: normal;
        font-weight: 400;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        position: absolute;
        border: 0;
        bottom: -25px;
        top: auto;
        right: 100px;
    }
    .report-progress--group .btn:last-child:after { content: none;}
}

.sent-time {
    display: inline-block;
}

// ########## Project ##########
@media only screen and (max-width: 767px) {
    .btn-read--project { display: block;}
    .sent-time { margin-top: 10px;}
}

@media only screen and (max-width: 767px) {
    .list-inline li {
        display: block;
    }
}

.template-form {
    display: none;
}

.section-content {
    // padding: 25px;
    margin-bottom: 40px;
}

.section-subheader {
    color: $text-pill;
}

/* Reset nav-pill with nav-pill--1 class */
.nav-pills--1 {
    margin-bottom: -1px;

    > li {
        border: 1px solid transparent;
        border-bottom: none;
        border-radius: 4px 4px 0 0;
        > a {
            margin: 0 6px 8px 6px;
            padding: 9px 15px;
            background-color: #EEEEEE;
            color: $text-pill;
            border: 2px solid transparent;
        }
        > a.btn-dashed-border {
            border: 1px dashed $border;
            background-color: white;
        }
        > a:hover {
            background-color: darken(#EEEEEE, 10%);
        }
    }
    > li.active {
        border: 1px solid $border;
        border-bottom: none;
        background-color: white;
        
        > a, > a:hover, > a:focus {
            color: $text-dark;
            background-color: transparent;
            outline: none;
        }
    }
}

.btn .glyphicon {
    margin-right: 8px;
}

.btn[class*="btn-add-"] {
    border: 1px dashed $border-dashed;
    border-radius: 3px;
    padding: 12px;
}

.btn-add-activity { margin-bottom: 10px;}

/*
** Risk form
*/
.risks {
    margin-bottom: 50px;
    .form-group > label { font-weight: 400; }
    .form-group--wrapper {
        margin-bottom: 28px;
        .form-group {
            margin-bottom: 0;
        }
    }
}
.risks-calculated {
    line-height: 36px;
    box-shadow: none;
    border-color: $border;
    background-color: $bg-form-with-delete-bar;
    .risks-score { 
        margin-bottom: 25px;
    }
    .result {
        display: inline-block;
        border: 1px solid $border;
        border-radius: 3px;
        margin-right: 10px;
        width: 80px;
        height: 36px;
        box-sizing: border-box;
        padding: 0 7px;
        background-color: white;
    }
}

.report-progress {
    > .col-md-3 {
        width: 220px;
    }
}


// Activities

#activities-full {
    .list-group-item {
        margin-bottom: 20px;
        padding-top: 0;
        .row-action {
            padding-top: 10px;
            border-top: 1px solid #e0e0e0;
            margin-top: 20px;
        }
        .activity-btn-action button {
            margin: 5px;
        }
    }
}

.activity-counter {
    font-size: 22px;
    line-height: 30px;
    font-weight: normal;
    color: #777;
    padding: 2px 10px;
    float: left;
    margin-right: 20px;
    background-color: #ddd;
    min-width: 40px;
}

.block {
    text-align: center;
}
 
.block:before {
    content: '';
    display: inline-block;
    height: 100%; 
    vertical-align: middle;
    margin-right: -0.25em; /* Adjusts for spacing */
      
    background: #808080; width: 5px;

}
 
.centered {
    display: inline-block;
    vertical-align: middle;
    padding: 10px 15px;
    border: #a0a0a0 solid 1px;
    background: #f5f5f5;
}

// Project Overview -- Risk
.project-risk {
    clear: both;
    // overflow: hidden;

    h3 {
        display: inline-block;
    }
    .label--risk {
        display: block;
        margin-top: 17px;
        margin-bottom: 17px;
        margin-left: -1px;
        padding: 0 32px;
        float: left;
        height: 36px;
        line-height: 36px;
    }
    .thead-title {
        color: $text-table;
        border-bottom: 2px solid $border-radio;
        margin-bottom: 0;
        font-size: 20px;
    }
    .project-risk--table {
        margin-bottom: 2em;
    }
    .project-risk--row {
        width: 100%;
        float: left;
        border-bottom: 1px solid $border-radio;
        padding: 10px 0;
        .project-risk--type,
        .project-risk--col {
            display: table;
            min-height: 38px;
            padding: 0;
        }
        .project-risk--type {
            font-weight: bold;
        }
        .project-risk--type span {
            display: table-cell;
            height: 38px;
            vertical-align: middle;
        }
        .project-risk--choice {
            text-align: left;
            font-size: 13px;
            .choice-wrapper span {
                padding: 8px 16px;
                display: inline-block;
            }

        }
    }


    .label-default.label-tag {
        display: inline-block;
        border-radius: 18px;
        font-size: 13px;
        min-width: 155px;
        line-height: 18px;
        margin-right: 0;
        .badge {
            background-color: transparent;
        }
    }
}
@media only screen and (max-width: 768px) {
    .project-risk--choice.clear { clear: both;}
}

@media only screen and (max-width: 560px) {
    .project-risk--choice { width: 100%;}
}

.choice-wrapper-active {
    span {
        padding-left: 20px;
        position: relative;
        display: inline-block;
    }
    .glyphicon {
        position: absolute;
        left: -3px;
        margin-right: 0;
    }   
}

.indicator_percent {
    float: left;
    width: 10%;
    padding: 10px 10px 10px 5px;
    border-top: 1px solid $border-radio;
    border-right: 1px solid $border-radio;
    .pull-left {
        font-size: 11px;
        width: 100%;
    }
    &--last {
        border-right: none;
    }
}

@media only screen and (max-width: 767px) {
    .indicator_percent { width: 50%;}
}

.indicator_risk {
    float: left;
    width: 25%;
    padding: 15px 15px 15px 20px;
    border-top: 1px solid $border-radio;
    border-right: 1px solid $border-radio;
    &--last {
        border-right: none;
    }
}
.risk-label { font-size: 14px; line-height: 10px;}

@media only screen and (max-width: 767px) {
    .indicator_risk { width: 50%;}
    .indicator_risk.even { border-right: 0;}
}

.risk-label {
    float: left;
}

@media only screen and (max-width: 991px) {
    .risk-label {float: none;}
    .amount-with-unit { clear: both;}
}

.amount-with-unit {
    margin-top: 15px;
    float: right;
}
// .amount-with-unit--single-line {
//     margin-top: 0;
// }
.amount-with-unit--amount {
    display: block;
    text-align: center;
    font-size: 24px;
    line-height: 18px;
    font-weight: bold;
}
.amount-with-unit--unit {
    font-size: 11px;
}

.indicator_row {
    width: 100%;
    float: left;
    border-top: 1px solid $border-radio;
}
.indicator_row--table {
    display: table;
    .indicator_column {
        display: table-cell;
        vertical-align: top;
        border-right: 1px solid $border-radio;
    }
    .indicator_column--fix-width {
        width: 40%;
    }
    .indicator_column--last {
        border-right: none;
    }
    .indicator_item {
        border-right: none;
    }
}
.indicator_item {
    float: left;
    padding: 15px;
    border-right: 1px solid $border-radio;
}
.indicator_item-left {
    padding-left: 0;
    min-width: 100px;
}

@media only screen and (max-width: 767px) {
    .indicator_item {
        width: 50%;
        border-right: 0;
    }
    .indicator_item-left { border-right: 1px solid $border-radio;}
    .indicator_column {
        width: 100%;
        display: block;
        .indicator_item {
            width: 100%;
            border-bottom: 1px solid #eee;
            padding: 15px 0;
        }
    }
    .indicator_row--table .indicator_column--fix-width { width: 100%;}
    .indicator_row--table .indicator_column { 
        display: block;
        border-right: 0;
        overflow: hidden;
    }
}

.indicator_item__content__label {
    font-size: 12px;
    margin-top: 10px;
    line-height: 20px;
}
.indicator_item__content__amount {
    font-size: 15px;
    font-weight: bold;
}

.indicator_item__label { font-size: 14px;}
.indicator_item__content { margin-bottom: 20px;}
.indicator_item__content.last { margin-bottom: 0;}

.activities-action-wrapper { float: none;}
.project-activities-action {
    padding: 20px;
    overflow: hidden;
    .btn { border: 0; border-radius: 0;}
    .btn:first-child { border-radius: 3px 0 0 3px;}
    .btn:first-child+.btn { border-radius: 0 3px 3px 0;}
    .btn-color--blue { border-radius: 3px;}
}

.activities-attachments {
    .item-file {
        padding: 10px 20px 10px 50px;
        border: 1px solid #e0e0e0;
        position: relative;
        border-radius: 3px;
        margin: 0 0 1% 1%;
    }
    .item-file a { font-size: 14px;}
    .glyphicon-file {
        position: absolute;
        top: 15px;
        left: 15px;
        font-size: 25px;
        color: #999;
    }
    .file-title {
        font-size: 14px;
        margin-bottom: 2px;
    }
}

.activities-youtubes {
    li { width: 180px; position: relative; margin-bottom: 1em;}
    img { height: auto;}
    .glyphicon-play-circle {
        font-size: 38px;
        color: #fff;
        position: absolute;
        top: 40px;
        z-index: 99;
        left: 65px;
    }
}

.time-event-wrapper {
    background-color: #f3f3f3;
    margin: 0 20px;
    box-sizing: border-box;
    max-width: 90px;
    text-align: center;
    padding: 15px 0 0;
    line-height: 18px;
}

.day-month {
    @extend .font-bold;
    font-size: 28px;
    display: block;
    margin: 5px 0;
}

.day-event { margin-bottom: 10px;}
.time-event { 
    @extend .font-bold;
    padding: 10px 2px;
    background-color: #E6E6E6;
}

@media only screen and (max-width: 991px) {
    .time-event-wrapper {
        max-width: initial;
        padding: 0;
    }
    .day-event {
        display: inline-block;
        margin: 10px 0;
    }
    .day-month-year {
        padding: 0;
        border: 0;
        font-size: 24px;
    }
    .day-month {
        font-family: 'th_sarabun_new',"Thonburi", "Helvetica Neue",Helvetica,Arial,sans-serif;
        display: inline-block;
        font-size: 24px;
        font-weight: 100;
        margin: 0 4px;
    }
}

@media only screen and (max-width: 991px) {
    .time-event-wrapper {
        background-color: transparent;
        border-bottom: 1px solid #ccc;
        padding: 10px 0;
        text-align: left;
    }
    .time-event {
        background-color: transparent;
        padding: 0;
    }
}

.title-activity { font-size: 28px; margin-bottom: 5px;}

.label-filefield {
    margin-bottom: 5px;
}

.label-field {
  @extend .font-bold;
  font-size: 24px;  
  margin-bottom: 5px;
}

.detail-field { margin-top: 30px;}
.detail-field p { color: #444; white-space: pre-line;}

.activities-attachments {
    list-style: none;
    clear: both;
    overflow: hidden;
    padding-left: 0;
    margin: 0 -1%;
    li {
        overflow: hidden;
        width: 180px;
        height: 122px;
        background-color: #fff;
        text-align: center;
        margin: 0 1% 2%;
        border: 2px solid #eee;
        padding: 3px;
        float: left;
    }
    li img { 
        display: inline-block;
    }
    .img-responsive-wrapper {
        height: 112px;
        overflow: hidden;
    }
}

.activities-files li {
    height: auto;
    text-align: left;
    width: auto;
    &.item-file { background-color: transparent;}
}



@media only screen and (max-width: 640px) {
    .activities-attachments li {
        width: 48%;
    }
    .activities-youtubes li { width: 180px;}
    .activities-files li { width: 100%;}
}

.btn-toggle-group {
    list-style: none;
    padding: 0;
    margin: 15px 0;
}

.btn-toggle-group li {
    display: inline-block;
    margin-left: 5px;
    &.active .btn { background-color: #e1e1e1;}
}

@media only screen and (max-width: 540px) {
    .btn-list .btn { 
        width: 100%; 
        margin-bottom: 1px;
        border-radius: 3px;
    }
    .project-activities-action.btn-list .btn {
        border-radius: 3px;
        margin-bottom: 5px;
    }
}

.url-input .form-control {
    margin: 0 5px 5px 0;
    width: 80%;
}

@media only screen and (max-width: 768px) {
    .url-input .form-control { width: 100%;}
}

.url-input .btn { margin-bottom: 5px;}

// Activity Form
.form-date {
    position: relative;

    .calendar {
        position: absolute;
        top: 56px;
        z-index: 20;
        right: 0;
    }
}


@media (min-width: 768px) {
    #modal-new-activity-form .modal-dialog {
      width: 700px;
    } 
}

.form-report-objective-activity {
    .counter {
        font-size: 18px;
        float: left;
        margin-right: 20px;
        padding-right: 10px;
        height: 40px;
        font-weight: bold;
    }
    .name { 
        font-size: 20px; 
        margin-bottom: 20px;
    }
    .description { 
        margin-bottom:20px;
        font-size: 15px;
        line-height: 28px;
        color: #444;
    }

}

.activity-wrapper {
    margin-bottom: 30px;
}

.activity-header { 
    font-size: 18px; 
    width: 100%;
    margin-bottom: 5px;
    color: #4A7CB5;
    .glyphicon { margin-right: 5px;}
}
.edit-activity-wrapper {
    margin-top: 20px;
    padding-top: 20px; 
    border-top: 1px dashed #ccc;
}
.activity-edit { margin-bottom: 5px;}
.activity-date, .activity-place { display: inline-block; margin-right: 5px;}
.activity-detail-pre-wrap { white-space: pre-wrap; }
.comment {
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
    background-color: #f8f8f8;
}

.post-by {
    color: #999;
    margin-left: 5px;
    font-size: 12px;
}

.activities-group-select {
    margin-bottom: 0;
    .media {
        padding: 5px 10px 10px;
        margin: 0 0 10px;
        border-bottom: 1px solid #ebebeb;
    }
    .media:last-child { border-bottom: 0;}
}

.download-csv {
    margin-top: 20px;
    float: right;
}

@media only screen and (max-width: 767px) {
    .download-csv {
        float: none;
        margin: 10px 0;
        clear: both;
    }
}

.confirm-action {
    .btn-cancel { margin-right: 1em;}
}

.tab-content-white {
    padding: 25px;
    margin-bottom: 40px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: 2px solid #eee;
    @include border-box;
}

.tab-content-white h1 {
    margin: 0 0 5px;
}

.tab-content .list-group-item:first-child { border-top: 0;}

.paragraph-wrapper { margin-bottom: 25px;}

.detail-budget-field {
    thead { font-size: 14px;}
    .field-label { 
        display: block;
        font-size: 16px;
        font-weight: bold;
        margin: 10px 0;
        clear: both;
    }
    th { width: 50%;}
    .title-amount, .amount { text-align: right;}
}

.summary-amount { font-weight: bold;}


.tab-activities .list-group-item:first-child .text-empty { padding-top: 15px;}

// ######### MAP #########
.level-1 { background-color: #ffc04d;}
.level-2 { background-color: #ffb733;}
.level-3 { background-color: #ffae1a;}
.level-4 { background-color: #ffa500;}
.level-5 { background-color: #e69500;}
.level-6 { background-color: #cc8400;}

.ex-color {
    width: 15px;
    height: 15px;
    display: inline-block;
    margin: 2px 10px 0 0;
    float: left;
}

.map-value {
    display: inline-block;
    float: left;
    font-size: 12px;
    line-height: 20px;
}

.map-instruction {
    background-color: #fff;
    padding: 10px;
    position: fixed;
    right: 30px;
    bottom: 30px;
}

.map-instruction ul { margin: 0;}
.map-instruction .copyright {
    font-size: 12px;
    margin-bottom: 0;
    color: #444;
}

.map-instruction .map-unit {
    font-size: 12px;
    color: #333;
    margin-bottom: 3px;
}

.map-color { overflow: hidden;}
.map-color.last { margin-bottom: 0;}

.list-map-filter {
    font-size: 12px;
    margin-bottom: 0;
}

.ex-project a { min-width: 100px; display: inline-block;}

.map-wrapper { 
    position: absolute; 
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    padding-top: 160px;
}

.map__filter_wrapper {
    position: relative;
    .header-sub-content { margin-bottom: 0; padding-bottom: 10px;}
    .indicator__filter { padding-bottom: 0;}

    .loading-icon {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -250px;
    }
}

.map__filter { 
    position: absolute;
    bottom: -50px;
    right: 15px;
}

.indicator__wrapper_map {
    padding-bottom: 10px;
}

.loading-icon {
    margin: 60px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(255, 255, 255, 0.5);
    border-right: 1.1em solid rgba(255, 255, 255, 0.5);
    border-bottom: 1.1em solid rgba(255, 255, 255, 0.5);
    border-left: 1.1em solid #fff;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
    border-radius: 50%;
    width: 10em;
    height: 10em;
}

.loading-icon-green {
    border-top: 1.1em solid rgba(224, 224, 224, 0.5);
    border-right: 1.1em solid rgba(224, 224, 224, 0.5);
    border-bottom: 1.1em solid rgba(224, 224, 224, 0.5);
    border-left: 1.1em solid #80DCAC;
}

loading-icon,
.loadeding:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.panel-loading { margin-top: 50px;}

// ######### User Project #########
.project-name {
    display: inline-block;
    margin-bottom: 10px;
}

// Activity Budget 
table td.text-center { text-align: center;}
tr.summary td { 
    font-weight: bold;
}

.responsive-table {
    th, td { text-align: right;}
    th.title, td.title {
        max-width: 350px;
        text-align: left;
    } 
    .summary-cost { font-weight: bold;}
    // .inline-block { display: inline-block !important;}
}

@media only screen and (max-width: 991px) {

    .responsive-table {
        .inline-block { 
            display: inline-block !important;
            min-width: 50%;
        }
        .amount { width: 50%; display: inline-block;}
        tbody tr td { padding: 0 8px;}
        tbody tr { padding: 10px;}
    }

    .responsive-table th.title, 
    .responsive-table td.title {
        max-width: initial;
        margin-bottom: 10px;
    }
    
    /* Force table to not be like tables anymore */
    .responsive-table table, 
    .responsive-table thead, 
    .responsive-table tbody, 
    .responsive-table th, 
    .responsive-table td, 
    .responsive-table tr { 
        display: block; 
    }
 
    /* Hide table headers (but not display: none;, for accessibility) */
    .responsive-table thead tr { 
        position: absolute;
        top: -9999px;
        left: -9999px;
    }
 
    .responsive-table tr { border: 1px solid #ccc; }
 
    .responsive-table td { 
        /* Behave  like a "row" */
        border: none;
        position: relative;
        white-space: normal;
        text-align:left;
    }
 
    .responsive-table td:before { 
        /* Now like a table header */
        position: absolute;
        /* Top/left values mimic padding */
        top: 6px;
        left: 6px;
        width: 45%; 
        padding-right: 10px; 
        white-space: nowrap;
        text-align:left;
        font-weight: bold;
    }
 
    /*
    Label the data
    */
    .responsive-table td:before { content: attr(data-title); }
}

@media only screen and (max-width: 480px) {
    .responsive-table .amount { text-align: right;}
}

.financial-final-wrapper {
    max-width: 560px;
}

@media only screen and (max-width: 991px) {
    .financial-final-wrapper { max-width: initial;}
    .row-table { margin-bottom: 0;}
    .row-section { margin-top: 5px;}
    .row-table .title { padding-left: 10px;}
}

.lastphase-financial-report {
    .label-field {
      display: inline-block;
      padding: 0;
      font-weight: bold;
      margin-bottom: 20px;
      font-family: "Thonburi", "Helvetica Neue",Helvetica,Arial,sans-serif;
      background-color: transparent;
      font-size: 16px;
    }
}

.sector1-financial-report { padding-top: 10px;}

.sector2-financial-report { margin-bottom: 0;}

// Guideline
.guideline-item {
    padding-bottom: 10px;
    margin-bottom: 10px;
    .title {
        font-weight: bold;
        margin-bottom: 10px;
    }
    .list {
        list-style: none;
        padding-left: 0;
    }
    .glyphicon {
        margin-right: 5px;
    }
}


#modal-report-step-log {
    .modal-body {
        li {
            margin-bottom: 20px;
        }
    }
}

.btn-step-log {
    margin-left: 15px;
    text-decoration: underline;
}

.outcome-other-detail {
    color: #777;
    font-size: 14px;
}

.nav-role {
    position: absolute;
    right: 0;
    margin-right: 15px;
    font-size: 12px;
    opacity: 0.6;
    width: 100%;
    min-width: 200px;
    text-align: right;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {-moz-appearance: textfield;}

.color-gray {
    color: #888;
}

.just-warning .errorlist {
    border-color: #EC7406;
    color: #EC7406;
    background-color: #fcf8e3;
}

.jq-toast-wrap {
    width: auto;
    opacity: 0.5;
}

.main-loading {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    z-index: 999;
    opacity: 0.5;

    .main-loading-content {
        width: 400px;
        position: absolute;
        top:50%;
        margin-top: -200px;
        left: 50%;
        margin-left: -200px;
    }
    .glyphicon-refresh-animate {

        font-size: 50px;
        -animation: spin .7s infinite linear;
        -ms-animation: spin .7s infinite linear;
        -webkit-animation: spinw .7s infinite linear;
        -moz-animation: spinm .7s infinite linear;
    }

    @keyframes spin {
        from { transform: scale(1) rotate(0deg);}
        to { transform: scale(1) rotate(360deg);}
    }

    @-webkit-keyframes spinw {
        from { -webkit-transform: rotate(0deg);}
        to { -webkit-transform: rotate(360deg);}
    }

    @-moz-keyframes spinm {
        from { -moz-transform: rotate(0deg);}
        to { -moz-transform: rotate(360deg);}
    }

}

.header-wrapper .node-content,
.node-content {
    padding: 20px 30px;
    border-bottom: 0px solid #ccc;
}

.node-form .form-actions {
    margin-left: -1px;
    margin-right: -1px;
}
.node-total-section {
    border-top: 1px solid #ccc;
}
.form-group.full-width ul.select2-selection__rendered {
    .select2-selection__clear {
        display: none;
    }
    li.select2-selection__choice {
        width: 100%;
        padding: 10px;

        .select2-selection__choice__remove {
            margin-right: 10px;
        }
    }
    input.select2-search__field,
    .select2-search.select2-search--inline {
        width: 100% !important;
    }
}


.inline-multiple-checkbox {
    ul {
        padding: 5px 0px;
        list-style: none;

        li {
            display: inline-block;
            margin-right: 10px;

            input {
                margin-right: 5px;
            }
        }
    }

}
.inline-multiple-checkbox.-border {
    &.has-error ul {
        border-color: #a94442;
    }
    ul {
        padding: 5px 5px 0px 10px;
        border: solid 1px #ccc;
        border-radius: 4px;
    }
    .is-disabled ul {
        background-color: #eee;
    }
}

.inline-multiple-checkbox.-col {
    ul {
        li {
            width: 250px;
        }
    }
}

.inline-multiple-checkbox, .inline-block-multiple-checkbox {
    .multiple-checkbox label {
        font-weight: normal;
    }
}

.select-date-inline > div {
    overflow: hidden;
    margin-right: -15px;
}
.select-date-wrapper {
    width: 33.33%;
    padding-right: 15px;
    float: left;
}

.formset-wrapper .form-with-delete-bar > .form-group.form-field-DELETE {
    display: none;
}

.form-group .select2-container .select2-selection--single {
    height: 34px;
}

.form-group .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 30px;
}

.node-form #section-projects .list-group-item {
    padding-left: 0;
}

.account-form .form-group {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
}

.account-form label {
    font-weight: normal;
    padding-left: 0;
}

.account-form .select2-container {
    min-width: inherit;
}

.node-form {
    #NodeKnowledge-knowledges-formset select[disabled] {
        border: none;
        padding: 0;
        margin: 0;
        outline: none;
        -webkit-appearance: none;
        box-shadow: none;
        cursor: text;
        font-size: 18px;
        font-weight: bold;
        color: #333;
        display: inline-block;
    }
    #NodeKnowledge-knowledges-formset .form-field-category > div {
        display: inline-block;

    }

    .formset-wrapper .form-field-issues li {
        width: 242px;
    }
}

.formset-wrapper hr {
    border-top: 1px solid #CCC;
}

.has-error .is-disabled .help-block {
    display: none;
}

.is-disabled-wrapper.has-error .control-label {
    color: #333333;
}

.alert-error {
    border-color: #E35656;
    color: #E35656;
    background-color: #f2dede;
}

.disabled-all {
    .form-actions {
        display: none;
    }
    .formset-wrapper {
        .btn-remove, .formset-add-button, .files-widget .message, .fake-files-input {
            display: none;
        }
    }
}


// file icon


.file-icon {
    font-family: Arial, Tahoma, sans-serif;
    font-weight: 300;
    display: inline-block;
    width: 24px;
    height: 32px;
    background: #888888;
    position: relative;
    border-radius: 2px;
    text-align: left;
    -webkit-font-smoothing: antialiased;
}
.file-icon::before {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-bottom-left-radius: 2px;
    border-width: 5px;
    border-style: solid;
    border-color: #fff #fff rgba(255,255,255,.35) rgba(255,255,255,.35);
}
.file-icon::after {
    display: block;
    content: attr(data-type);
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 10px;
    color: #fff;
    text-transform: lowercase;
    width: 100%;
    padding: 2px;
    white-space: nowrap;
    overflow: hidden;
}


.file-icon-lg {
    width: 48px;
    height: 64px;
    border-radius: 3px;
}
.file-icon-lg::before {
    border-bottom-left-radius: 2px;
    border-width: 8px;
}
.file-icon-lg::after {
    font-size: 16px;
    padding: 4px 6px;
}

.file-icon[data-type^=pdf] {
    background: #126c63;
}
.file-icon[data-type^=doc] {
    background: #307cf1;
}
.file-icon[data-type^=ppt] {
    background: #d24726;
}
.file-icon[data-type^=xls] {
    background: #0f9d58;
}
.file-icon[data-type=bmp], .file-icon[data-type=jpg], .file-icon[data-type=jpeg], .file-icon[data-type=gif], .file-icon[data-type=png] {
    background: #f4b400;
}
.file-icon[data-type=mp3], .file-icon[data-type=wma], .file-icon[data-type=m4a], .file-icon[data-type=flac] {
    background: #8e44ad;
}