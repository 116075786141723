@mixin border-box {
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
}

.middle {
	display: table-cell;
	vertical-align: middle;
}

.no-bullet {
	list-style: none;
	padding: 0;
}
