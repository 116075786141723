.alert {
    color: #444;
    padding: 10px 15px;
    font-size: 14px;
    .glyphicon {
        font-size: 45px;
        margin-right: 20px;
        float: left;
    }
    .middle { height: 45px;}
    .phase { margin-right: 5px;}
}

.alert-warning {
    border-color: $orange;
    color: $orange;
    .glyphicon {
        color: rgba(236, 116, 6, 0.56);
    }
}

.alert-danger {
    border-color: $red;
    color: $red;
    .glyphicon {
        color: rgba(227, 86, 86, 0.8);
    }
}

.errorlist {
    li { padding: 5px 0; }
}

ul.errorlist, .errorlist {
    border: 1px solid $red;
    color: $red;
    background-color: #f2dede;
    font-size: 13px;
    list-style: inside;
    margin: 5px 0 15px;
    clear: both;
    padding: 5px 20px;
    list-style: none;
    border-radius: 3px;
    &.nonfield {
        padding: 0 0 0 10px;
        margin: 0 0 0 5px;
        border: 0;
    }
    li { padding: 0;}
}

.error-block {
    margin-top: 5px;
    clear: both;
    overflow: hidden;
    .errorlist { margin: 0;}
}

.help-block-error {
    font-size: 13px;
    margin-bottom: 0;
    .errorlist {
        padding: 0;
        list-style: inside;
        margin: 0;
        border: 0;
        background-color: transparent;
    }
}


.badge-phase {
    font-size: 15px;
    color: #444;
    padding: 10px 15px;
    background-color: #eee;
    text-decoration: none;
    margin-right: 5px;
}

.tooltip-inner {
    padding: 15px;
    font-size: 12px;
    text-align: left;

}

.table>tbody>tr>td, .table>tbody>tr>th, 
.table>tfoot>tr>td, .table>tfoot>tr>th, 
.table>thead>tr>td, .table>thead>tr>th {
    border-top: 0;
}

.right { text-align: right;}
.row-table { margin-bottom: 1em;}

hr.dashed { border-top: 1px dashed #ccc;}

// .clear { clear: both;}

// Page Error
.error-page {
    color: #ddd;
    text-align: center;
    margin: 100px 0 80px;
    .page-title { font-size: 50px;}
    .description { color: #ccc; font-size: 18px; letter-spacing: 1px;}
    .btn { text-transform: uppercase;}
    .glyphicon-wrench { 
        font-size: 270px;
        line-height: 250px;
        margin: 100px 0 20px;
    }
}
.big-text {
    font-size: 110px;
    text-transform: uppercase;
}
.error-number {
    font-size: 270px;
    line-height: 190px;
    margin: 50px 0 20px;
    &.icon-tools {
        font-size: 190px;
        margin: 20px;
    }
}

@media (max-width: 480px) {
    .error-page {
        .page-title { font-size: 30px;}
        .btn { margin-top: 10px; display: inline-block;}
    }
    .big-text { font-size: 50px;}
    .error-number { font-size:100px; line-height: 80px;}
    .error-number.icon-tools { font-size: 100px;}
}