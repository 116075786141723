/* text color */
$text-default:      #333333;
$text-dark:         #000000;
$text-label:        #444444;
$text-pill:         #6B6B6B;
$text-icon:         #888888;
$text-sub-blue:     #A6CEE5;
$text-sub-milktea:  #9C6C41;
$text-table:        #777777;

// set color class
$color: ( 
    default     $text-default,
    dark        $text-dark,
    label       $text-label,
    pill        $text-pill,
    icon        $text-icon,
    sub-blue    $text-sub-blue,
    sub-milktea $text-sub-milktea,
    table       $text-table
);

// Making class text color
@each $item in $color {
    .text-#{nth($item, 1)} {
        color: nth($item, 2);
    }
}

/* main color (CI) */
$blue:              #4A7CB5;
$green:             #2DA888;
$orange:            #EC7406;
$red:               #E35656;
$approve:           #2da888;
$milktea:           #FCAA5F;
$grey:              #eee;
$yellow:            #FFFFCC;

// set color class
$color: ( 
    -blue       $blue,
    -green      $green,
    -orange     $orange,
    -red        $red,
    -approve    $approve,
    -milktea    $milktea,
    -grey       $grey
);

.text-grey { color: $text-table;}

// Making class text color
@each $item in $color {
    .color-#{nth($item, 1)} {
        color: nth($item, 2);
    }
}
// Making class button background color
@each $item in $color {
    .btn-color-#{nth($item, 1)} {
        color: #fff;
        background-color: nth($item, 2);
        border: 1px solid nth($item, 2);
        &:hover, &:active, &:focus {
            background-color: darken(nth($item, 2), 5%);
            border: 1px solid nth($item, 2);
        }
    }
}
.btn-color--approve {
    color: #fff;
    margin-left: 15px;
}
.btn-color--send,
.btn-color--reject {
    margin-left: 15px;
}
.btn-color--blue {
    h5 { margin-bottom: 0; }
    .text-sub {
        color: $text-sub-blue;
    }
    &:hover { color: white; }
}
.btn-color--milktea {
    h5 { margin-bottom: 0; }
    .text-sub {
        color: $text-sub-milktea;
    }
}

.btn-color--grey {
    color: #333;
    border: 1px solid #e0e0e0;
}

.btn-color--orange, .btn-color--blue { 
    color: #fff;
    &:hover, &:active, &:focus { color: #fff;}
}

.btn-edit, .btn-remove {
    font-size: 12px;
    padding: 3px 10px;
    color: #666;
}

/* risk color */
$low:       #B8E986;
$mid:       #FFAB00;
$high:      #FF4E46;

// set color class
$color: ( 
    -low        $low,
    -mid        $mid,
    -high       $high
);

// Making class text color
@each $item in $color {
    .color-#{nth($item, 1)} {
        color: nth($item, 2);
    }
}
// Making class text color
@each $item in $color {
    .label-#{nth($item, 1)} {
        background-color: nth($item, 2);
    }
}

$border:            #e0e0e0;
$border-dashed:     #ccc; 
$border-active:     #EC7406;
$border-progress-pass:   #e0e0e0;
$border-progress-current: #85c2fd;
$border-progress-default: #e0e0e0;
$border-radio:      #ccc;
$border-header-bottom: #ccc;

/* background color */
$bg-body:                   #F4F4F4;
$bg-form-with-delete-bar:   #f4f4f4;
$bg-field:                  #FFFFCC;
$bg-header:                 #19988b;
// $bg-label:                  #EEEEEE;
$bg-label:                  #eee;
$bg-progress-pass:          #fff;
$bg-progress-current:       #6EA84A;
$bg-progress-default:       #fff;
$bg-nav-dashboard:          #126c63;
$bg-nav-dashboard-active:   #39B9A0;
